import { Routes, Route } from "react-router-dom";
import VLayout from "./components/visitor/VLayout";
// import ALayout from "./components/admin/ALayout";

// users
// import ALogin from "./components/admin/Login/ALogin";
// import ADashboard from "./components/admin/Dashboard/ADashboard"

// MODAL
import { Suspense, useEffect, useState} from "react";
import { ModalContainer } from "react-modal-global";
// import Modal_1 from "./components/visitor/common/Modals/Modal_1";
// import Modal_2 from "./components/visitor/common/Modals/Modal_2";
// REDUX
import { useDispatch, useSelector } from "react-redux";
// Protection
import PersistLogin from "./features/auth/PersistLogin"
import RequireAuth from "./features/auth/RequireAuth"
import { ROLES } from "./config/roles";
// RTK Query
import { useGetDetailsMutation } from "./features/details/detailsApiSlice";
// IP
import { setAppVersion } from "./features/details/detailsSlice";
// Pages
import PageNotFound404 from './components/visitor/common/PageNotFound404'
import VMain from './components/visitor/pages/VMain/VMain'

import ExCountdownLayout from "./components/student/ExLayouts/Countdown/ExCountdownLayout";
// MathJax
import { MathJaxContext } from "better-react-mathjax";
import Math from "./components/student/math/Math";
// Student
import SLayout from "./components/student/SLayout";

function App() {
    const dispatch = useDispatch();

    const [getDetails, {
        data: details,
        isLoading, 
        isSuccess, 
        isUninitialized,
        isError, 
        error
    }] = useGetDetailsMutation()
    
    const appVersion = useSelector(state => state.details.appVersion);
    
    useEffect( () => {  
        // Details
        getDetails()
        // force reload window
        const handleVisibilityChange = () => {
            if (document.visibilityState === "visible") {
              getDetails()
            }
        };

        document.addEventListener("visibilitychange", handleVisibilityChange);

        return () => {
          document.removeEventListener("visibilitychange", handleVisibilityChange);
        };
    }, [])

    useEffect(() => {
      if (isSuccess && details) {
          // details
          if(details.appVersion !== appVersion){
              dispatch(setAppVersion(details.appVersion))
              window.location.reload();
          }
          if(!showedOnce){
            setShowedOnce(true)
          }
      }
    }, [isSuccess, details])

    const [minimumLoadTimePassed, setMinimumLoadTimePassed] = useState(false);
    useEffect(() => {
        const timer = setTimeout(() => {
            setMinimumLoadTimePassed(true);
        }, 300); // Set minimum display time for preloader

        return () => clearTimeout(timer);
    }, []);

    const [showedOnce, setShowedOnce] = useState(false)
    if(isLoading || !minimumLoadTimePassed){
      if(!showedOnce || !minimumLoadTimePassed){
        // return <Preloader />
        return <span>loading</span>
      }
    }
    
    const config = {
        options: {
            enableMenu: false // Disables the context menu
        }
    };
    return (
      <>
      {/* <MathJaxContext config={config} src={"https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-svg.js"}> */}
      <MathJaxContext config={config} version={3} src={"/assets/mathjax/tex-svg.js"}>
         
            <Routes>
              {/* <Route path="/" element={<VLayout />}> */}
              <Route path="/">
                  <Route index element={<VMain />} />
              </Route>
              
              <Route element={<PersistLogin ifUnauthorisedRedirectTo='/' />}>
                      <Route element={<SLayout />} >
                          <Route element={<RequireAuth allowedRoles={[ ROLES.student, ROLES.teacher, ROLES.admin, ROLES.superAdmin, ROLES.owner]} ifNotAllowedNavigateTo='/admin' />}>
                              
                              <Route path="library">
                                  <Route index element={<Math />} />

                                  <Route path="math">
                                      <Route index element={<Math />} />
                                          <Route path="lesson01" element={<ExCountdownLayout exercise_id='task_01' />} />
                                          <Route path="lesson02" element={<ExCountdownLayout exercise_id='task_02' />} />
                                      
                                  </Route>
                              </Route>

                          </Route>
                      </Route> 
              </Route> 

              <Route path="*" element={<PageNotFound404 />} />
                {/* admin */}
                {/* <Route path="admin"> */}
                    {/* DASHBOARD */}
                    {/* <Route index element={<ALogin />} /> */}
                    {/* Protected Routes */}
                    {/* <Route element={<PersistLogin ifUnauthorisedRedirectTo='/admin' />}>
                        <Route element={<ALayout />} >
                            <Route element={<RequireAuth allowedRoles={[ ROLES.Nurse, ROLES.Doctor, ROLES.Admin, ROLES.SuperAdmin, ROLES.Owner]} ifNotAllowedNavigateTo='/admin' />}>
                                <Route path="dashboard" element={<ADashboard/>} />
                              
                            </Route>
                        </Route>
                    </Route>


                </Route> */}
                
            </Routes>
        <Suspense>
            {/* <ModalContainer controller={Modal_1} />
            <ModalContainer controller={Modal_2} /> */}
        </Suspense>
      
      </MathJaxContext>
      </>
    
  );
}

export default App;
