import styles from './Math.module.css'
import { Link } from 'react-router-dom'
// Images

const Chapter = () => {
    return  <>
                <Link to='/library/math/lesson01' className={styles.chapter}>
                    <span className={styles.lesson}>01 - Timestable - Multiplication</span>
                </Link>
                <Link to='/library/math/lesson02' className={styles.chapter}>
                    <span className={styles.lesson}>02 - Timestable - Division</span>
                </Link>
            </>
}
const Math = () => {
  return (
    <div className={styles.section}>
        <div className={`${styles.left} ${styles.wrapper}`}>
            left
        </div>

        <div className={`${styles.middle} ${styles.wrapper}`}>
            <div className={styles.subject_header}>
                <div className={styles.content}>
                    <h2 className={styles.subject_title}>Maths</h2>
                    <div className={styles.subject_stats}>
                        <div className={styles.stats_block}>
                            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.9023 3.5V9.5H23.9023V3.5M15.9023 21.5H23.9023V11.5H15.9023M5.90234 21.5H13.9023V15.5H5.90234M5.90234 13.5H13.9023V3.5H5.90234V13.5Z" fill="white" />
                            </svg>
                            1 chapter
                        </div>

                        <div className={styles.stats_block}>
                            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.9023 3.5V9.5H23.9023V3.5M15.9023 21.5H23.9023V11.5H15.9023M5.90234 21.5H13.9023V15.5H5.90234M5.90234 13.5H13.9023V3.5H5.90234V13.5Z" fill="white" />
                            </svg>
                            2 lessons
                        </div>

                        <div className={styles.stats_block}>
                            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.9023 3.5V9.5H23.9023V3.5M15.9023 21.5H23.9023V11.5H15.9023M5.90234 21.5H13.9023V15.5H5.90234M5.90234 13.5H13.9023V3.5H5.90234V13.5Z" fill="white" />
                            </svg>
                            2h
                        </div>
                    </div>
                </div>
            </div>

            <Chapter />

        </div>
        <div className={`${styles.right} ${styles.wrapper}`}>
            right
        </div>
    </div>
  )
}

export default Math